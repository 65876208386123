import { SanityQuery } from "../utils/sanity";
import { defaultLocale } from "../i18n.config";

const portableTextProjectionData = `content[] {
  ...,
  ...select(
    _type == "customImage" => {
      "blur": image.asset->metadata.blurHash,
      "url": image.asset->url,
      "width" : image.asset->metadata.dimensions.width,
      "height" : image.asset->metadata.dimensions.height,
    }
  ),
  ...select(
    _type == "customVideo" => {
      "source": file.asset->url,
      "poster": poster.asset->url,
      "ratio": poster.asset->metadata.dimensions.aspectRatio
    }
  ),
  ...select(
    _type == "profile" => {
      "imgBlur": profile_image.asset->metadata.blurHash,
      "imgUrl": profile_image.asset->url + "?w=500",
    }
  )
}`;

const articleListProjectionData = `{
  title,
  language,
  "slug" :slug.current,
  "image": image.asset->url + "?w=800",
  "blur": image.asset->metadata.blurHash,
  featured,
  customDisplayDate,
  "year": string::split(customDisplayDate, '-')[0],
  category->{
    color,
    title,
    "slug": slug.current
  },
}`;

const articleDisplayProjectionData = `{
  "slug": slug.current,
  title,
  language,
  description,
  "image": image.asset->url + "?w=800",
  "blur": image.asset->metadata.blurHash,
  customDisplayDate,
  "content": ${portableTextProjectionData},
  category->{
    color,
    title,
    "slug": slug.current
  },
  "year": string::split(customDisplayDate, '-')[0],
  "related": *[_type == "insight_article" && category->_id == ^.category->_id && _id != ^._id && language == $lang && !(_id in path("drafts.**"))]| order(_id desc)
  | order(customDisplayDate desc)[0...2]${articleListProjectionData}
}`;

// Get All categories
export const sanityCategories = new SanityQuery(
    `*[_type == "insight_category" && language == $lang && !(_id in path("drafts.**"))]`,
    `{
        "id": _id,
        "title": title,
        "slug": slug.current,
    }`,
    {
        lang: defaultLocale,
    }
);

// Get collection of articles for given local (and optionally category)
export const sanityArticles = new SanityQuery(
    `
 {
    $featured == true => {
      'featured': *[_type == "insight_article" && language == $lang && featured == true && ($category == null ||
        category._ref == $category) && !(_id in path("drafts.**"))]
    },
  }
  {
    !defined(featured) || count(featured) == 0 => {
      'articles': *[_type == "insight_article" &&
      language == $lang &&
      ($category == null ||
        category._ref == $category ) &&
      ($cursorDate == null ||
       customDisplayDate < $cursorDate ||
       (customDisplayDate == $cursorDate && slug.current > $cursorSlug)) &&
      featured == false &&
      !(_id in path("drafts.**"))]
      | order(slug.current desc)
      | order(customDisplayDate desc)
      [0...$limit]
    },
    defined(featured) && count(featured) > 0 => {
      'articles': *[_type == "insight_article" &&
      language == $lang &&
      ($category == null ||
        category._ref == $category ) &&
      ($cursorDate == null ||
       customDisplayDate < $cursorDate ||
       (customDisplayDate == $cursorDate && slug.current > $cursorSlug)) &&
      featured == false &&
      !(_id in path("drafts.**"))]
      | order(slug.current desc)
      | order(customDisplayDate desc)
      [0...$limit -1 ]
    },
    featured
  }`,
    `
  {
    'articles': [...featured, ...articles]
    ${articleListProjectionData}
  }`,
    {
        limit: 11,
        featured: true,
        lang: defaultLocale,
        cursorSlug: null,
        cursorDate: null,
        category: null,
    }
);

// Get total count of articles for given locale
export const sanityArticlesCount = new SanityQuery(
    `count(*[_type == "insight_article" && language == $lang && ($category == null ||category._ref == $category) && !(_id in path("drafts.**"))])`,
    "",
    {
        category: null,
    }
);

export const sanityArticle = new SanityQuery(
    `*[_type == "insight_article" && language == $lang && slug.current == $slug && !(_id in path("drafts.**"))][0]`,
    `${articleDisplayProjectionData}`,
    {
        lang: defaultLocale,
        slug: null,
    }
);

export const sanityArticleDraft = new SanityQuery(`*[_id == $id][0]`, `${articleDisplayProjectionData}`, {
    id: null,
    lang: defaultLocale,
    slug: null,
});
