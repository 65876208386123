import { sanityCategories, sanityArticlesCount, sanityArticles, sanityArticle, sanityArticleDraft } from "../data/insight-groq";

const DEFAULT_ARTICLE_LIMIT = 12;

export const state = () => ({
    articles: [],
    homeArticles: [],
    pressArticles: [],
    totalArticleCount: null,
    nextCursor: null,
    categories: [],
    currentCategory: null,
    article: null,
});

export const mutations = {
    setArticles(state, articles) {
        state.articles = articles;
    },

    setHomeArticles(state, homeArticles) {
        state.homeArticles = homeArticles;
    },

    setPressArticles(state, pressArticles) {
        state.pressArticles = pressArticles;
    },

    setArticle(state, article) {
        state.article = article;
    },

    setCategories(state, categories) {
        state.categories = categories;
    },

    setCurrentCategory(state, slug) {
        const search = state.categories.find((c) => c.slug == slug);
        state.currentCategory = !!search ? search : null;
    },

    setNextCursor(state, cursor) {
        state.nextCursor = cursor;
    },

    setTotalArticleCount(state, count) {
        state.totalArticleCount = count;
    },
};

export const actions = {
    async fetchTotalArticleCount(context, payload) {
        const count = await this.$sanity.fetch(
            sanityArticlesCount.getQuery(),
            sanityArticlesCount.defineParameters({
                lang: payload,
                category: context.getters.getCurrentCategory?.id ?? null,
            })
        );
        context.commit("setTotalArticleCount", count);
    },

    async fetchArticlesInitial(context, options) {
        try {
            const language = context.getters.getLanguageForLocaleCode(options?.lang ?? this.$i18n.locale);
            await context.commit("setArticles", []);
            await context.commit("setNextCursor", null);
            await context.dispatch("fetchTotalArticleCount", language);
            await context.dispatch("fetchArticles", {
                limit: context.getters.getCurrentCategory?.id ? DEFAULT_ARTICLE_LIMIT : 11,
            });
        } catch (error) {
            console.log("Failed to fetch insight articles: " + error);
            throw error;
        }
    },

    async fetchArticles(context, options) {
        try {
            const language = context.getters.getLanguageForLocaleCode(options?.lang ?? this.$i18n.locale);
            const { articles } = await this.$sanity.fetch(
                sanityArticles.getQuery(),
                sanityArticles.defineParameters({
                    limit: options?.limit ?? DEFAULT_ARTICLE_LIMIT,
                    featured: context.getters.getNextCursor == null,
                    lang: options.language ?? language,
                    cursorSlug: options?.cursorSlug ?? null,
                    cursorDate: options?.cursorDate ?? null,
                    category: context.getters.getCurrentCategory?.id ?? null,
                })
            );
            if (!!context.getters.getNextCursor) {
                context.commit("setArticles", [...context.getters.getArticles, ...articles]);
            } else {
                context.commit("setArticles", articles);
            }

            const { slug, customDisplayDate } = articles[articles.length - 1];
            context.commit("setNextCursor", {
                slug,
                date: customDisplayDate,
            });

            return articles;
        } catch (error) {
            console.log("Failed to fetch insight articles: " + error);
            throw error;
        }
    },

    async fetchHomeArticles(context, options) {
        const language = context.getters.getLanguageForLocaleCode(options?.lang ?? this.$i18n.locale);
        try {
            const { articles } = await this.$sanity.fetch(sanityArticles.getQuery(), sanityArticles.defineParameters({ lang: language, limit: 2 }));
            context.commit("setHomeArticles", articles);
        } catch (error) {
            console.log(error);
        }
    },

    async fetchPressArticles(context, options) {
        const language = context.getters.getLanguageForLocaleCode(options?.lang ?? this.$i18n.locale);
        // Get press category for current locale
        const categories = await this.$sanity.fetch(sanityCategories.getQuery(), sanityCategories.defineParameters({ lang: language }));
        const localCategory = categories.find((c) => c.slug == "press-release" || c.slug == "announcements");
        try {
            const { articles } = await this.$sanity.fetch(
                sanityArticles.getQuery(),
                sanityArticles.defineParameters({ lang: language, limit: 2, category: localCategory?.id ?? null })
            );
            context.commit("setPressArticles", articles);
        } catch (error) {
            console.log(error);
        }
        return;
    },

    async fetchCategories(context, options) {
        try {
            const language = context.getters.getLanguageForLocaleCode(options?.lang ?? this.$i18n.locale);
            const data = await this.$sanity.fetch(sanityCategories.getQuery(), sanityCategories.defineParameters({ lang: language }));
            context.commit("setCategories", data);
        } catch (error) {
            console.log("Failed to fetch insight categories: " + error);
            throw error;
        }
    },

    async fetchArticle(context, payload) {
        try {
            let data;
            const language = context.getters.getLanguageForLocaleCode(this.$i18n.locale);
            if (process.server && payload.params.preview) {
                data = await this.$sanity.fetch(
                    sanityArticleDraft.getQuery(),
                    sanityArticleDraft.defineParameters({ lang: language, slug: payload.slug, id: payload.params.id })
                );
            } else {
                data = await this.$sanity.fetch(sanityArticle.getQuery(), sanityArticle.defineParameters({ lang: language, slug: payload.slug }));
            }
            if (!data) return; //return early if no data

            context.commit("setArticle", data);
            return data;
        } catch (error) {
            console.log("Failed to fetch insight articles: " + error);
            throw error;
        }
    },
};

export const getters = {
    getArticles(state) {
        return state.articles;
    },

    getHomeArticles(state) {
        return state.homeArticles;
    },

    getPressArticles(state) {
        return state.pressArticles;
    },

    getArticle(state) {
        return state.article;
    },

    getCategories(state) {
        return state.categories;
    },

    getCurrentCategory(state) {
        return state.currentCategory;
    },

    getNextCursor(state) {
        return state.nextCursor;
    },

    getTotalArticleCount(state) {
        return state.totalArticleCount;
    },

    /**
     * Maps a locale code to a language code. Useful for
     * some languages in multiple locales.
     */
    getLanguageForLocaleCode() {
        return (code) => {
            switch (code) {
                case "jp":
                    return "ja";
                case "cn":
                    return "zh-CN";
                default:
                    return "en-US";
            }
        };
    },
};
