import Vue from "vue";

Vue.directive("autoplay", {
    inserted(element, binding, vnode) {
        const isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`);
        if (isReduced.matches || binding.value?.shouldAutoplay === false) return; //don't autoplay if prefers reduced motion, or doesn't exist
        let options = {
            root: null,
            rootMargin: "0px",
            threshold: [0.01, 0.99],
        };
        const observer = new IntersectionObserver(([entry] = entries) => {
            if (entry.isIntersecting) {
                element
                    .play()
                    .then(() => {
                        element.dispatchEvent(new CustomEvent("success")); //let us know you've succeeded
                    })
                    .catch((e) => {
                        console.error(e);
                        element.dispatchEvent(new CustomEvent("failed")); //let us know you've succeeded
                        const fallback = binding.value ? vnode.context.$refs[binding.value.ref] : null;
                        if (fallback) {
                            element.classList.add("autoplay--paused");
                            fallback.classList.add("autoplay--fallback");
                        }
                    });
                observer.disconnect();
            }
        }, options);

        observer.observe(element);
    },
});
