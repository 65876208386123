import { render, staticRenderFns } from "./MainNavDropdownSection.vue?vue&type=template&id=3ac880fc&scoped=true"
import script from "./MainNavDropdownSection.vue?vue&type=script&lang=js"
export * from "./MainNavDropdownSection.vue?vue&type=script&lang=js"
import style0 from "./MainNavDropdownSection.vue?vue&type=style&index=0&id=3ac880fc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ac880fc",
  null
  
)

export default component.exports