import { decode } from "blurhash";

export class SanityQuery {
    constructor(query = "", projection = "", parameters = {}) {
        this.query = query;
        this.projection = projection;
        this.parameters = parameters;
    }

    getQuery() {
        return this.query + this.projection;
    }

    defineParameters(object) {
        return { ...this.parameters, ...object };
    }
}

export function createImgBlur(hash, width = 200, height = 200) {
    const canvasWidth = 200; //you should not make a canvas larger than this. for speed reason
    const H = Math.floor(canvasWidth / (width / height));
    const pixels = decode(hash, canvasWidth, H);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const imageData = ctx.createImageData(canvasWidth, H);
    imageData.data.set(pixels);
    return imageData;
}

export function setImgBlur(canvas, data) {
    canvas.getContext("2d").putImageData(data, 0, 0);
}
