import { SanityQuery } from "../utils/sanity";

const positionDisplayProjectionData = `{
    title,
    description,
    "slug": slug.current,
    content,
    department,
    positionType,
    location,
    workplaceType,
    visaEligibility,
    applyUrl,
}`;

export const careersPositionCount = new SanityQuery(`*[_type == 'careers'][0]`, `{"count": count(positions[])}`);

export const careersPosition = new SanityQuery(`*[_type == "careers_position" && slug.current == $slug][0]`, `${positionDisplayProjectionData}`, {
    slug: null,
});

export const careersPositionDraft = new SanityQuery(`*[_type == "careers_position" && _id == $id][0]`, `${positionDisplayProjectionData}`, {
    id: null,
});

export const careersPositionsList = new SanityQuery(`*[_type == 'careers'][0]`, `{positions[]->{title, description, "slug": slug.current}}`);

export const careersPositionsHomeList = new SanityQuery(`*[_type == 'careers'][0]`, `{positions[]->{title, description, "slug": slug.current}}`);

export const talentList = [
    {
        avatar: "images/careers/avatars/19.jpg",
        name: "Jason C.",
        position: "Design",
        flag: "🇹🇼",
    },
    {
        avatar: "images/careers/avatars/16.jpg",
        name: "Xavier S.",
        position: "Engineering",
        flag: "🇦🇺",
    },
    {
        avatar: "images/careers/avatars/06.jpg",
        name: "Taieri C.",
        position: "Marketing",
        flag: "🇳🇿",
    },
    {
        avatar: "images/careers/avatars/17.jpg",
        name: "Raffael K.",
        position: "Engineering",
        flag: "🇩🇪",
    },
    {
        avatar: "images/careers/avatars/12.jpg",
        name: "Cheryl O.",
        position: "Design",
        flag: "🇦🇺",
    },
    {
        avatar: "images/careers/avatars/14.jpg",
        name: "Nikita K.",
        position: "Engineering",
        flag: "🇷🇺",
    },
    {
        avatar: "images/careers/avatars/20.jpg",
        name: "Willy B.",
        position: "Design",
        flag: "🇦🇺",
    },
    {
        avatar: "images/careers/avatars/28.jpg",
        name: "Ben M.",
        position: "Quality Assurance",
        flag: "🇺🇸",
    },
    {
        avatar: "images/careers/avatars/25.jpg",
        name: "Lauren S.",
        position: "Marketing",
        flag: "🇳🇿",
    },
    {
        avatar: "images/careers/avatars/08.jpg",
        name: "Mawish J.",
        position: "Recruitment",
        flag: "🇵🇰",
    },
    {
        avatar: "images/careers/avatars/24.jpg",
        name: "Roman Y.",
        position: "Design",
        flag: "🇷🇺",
    },
    {
        avatar: "images/careers/avatars/15.jpg",
        name: "Zeth G.",
        position: "Marketing",
        flag: "🇦🇺",
    },
    {
        avatar: "images/careers/avatars/23.jpg",
        name: "Graeme P.",
        position: "IT Support",
        flag: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    },
    {
        avatar: "images/careers/avatars/21.jpg",
        name: "Kevin R.",
        position: "Engineering",
        flag: "🇫🇮",
    },
    {
        avatar: "images/careers/avatars/27.jpg",
        name: "Julin L.",
        position: "Engineering",
        flag: "🇳🇿",
    },
    {
        avatar: "images/careers/avatars/13.jpg",
        name: "Kyle W.",
        position: "Design",
        flag: "🇺🇸",
    },
    {
        avatar: "images/careers/avatars/26.jpg",
        name: "Carl V.",
        position: "Engineering",
        flag: "🇦🇺",
    },
    {
        avatar: "images/careers/avatars/18.jpg",
        name: "Rumi Y.",
        position: "Marketing",
        flag: "🇯🇵",
    },
    {
        avatar: "images/careers/avatars/22.jpg",
        name: "Jefferson S.",
        position: "Recruitment",
        flag: "🇦🇺",
    },
];

export const inTheNews = [
    {
        image: "images/careers/publications/display-kyle.jpg",
        title: "Acclaimed digital brush creator Kyle T. Webster joins Procreate",
        publication: "Creative Bloq",
        icon: "images/careers/publications/icon-cb.png",
        url: "https://www.creativebloq.com/art/digital-art/renowned-brush-creator-kyle-t-webster-joins-procreate",
    },
    {
        image: "images/careers/publications/display-cb.jpg",
        title: "Apple Design Awards 2024: another win for Procreate",
        publication: "Creative Bloq",
        icon: "images/careers/publications/icon-cb.png",
        url: "https://www.creativebloq.com/art/digital-art-software/apple-design-awards-2024-another-win-for-procreate",
    },
    {
        image: "images/careers/publications/display-smh.jpg",
        title: "Apple’s iPad event: Five things you should know",
        publication: "Sydney Morning Herald",
        icon: "images/careers/publications/icon-smh.png",
        url: "https://www.smh.com.au/technology/apple-s-ipad-event-five-things-you-should-know-20240505-p5fozw",
    },
];

export const TassieSlides = [
    {
        src: "images/careers/slider/view.jpg",
        alt: "View of Hobart from atop Mt. Wellington/Kunanyi. The weather is bright and mild; in these conditions the hilly country-side is visible far in the distance across the Derwent River.",
    },
    {
        src: "images/careers/slider/franklin-square.jpg",
        alt: "People gather in Franklin Square to enjoy live entertainment. Families sit on colourful picnic blankets beneath shady trees.",
    },
    {
        src: "images/careers/slider/wallabies.jpg",
        alt: "Two wallabies grazing in a green field.",
    },
    {
        src: "images/careers/slider/food.jpg",
        alt: "Food and Wine on a wooden dining table. Patrons of the meal share many plates of different dishes.",
    },
    {
        src: "images/careers/slider/mountain.jpg",
        alt: "Mt. Wellington/Kunanyi viewed from South Hobart. ",
    },
    {
        src: "images/careers/slider/rainforest.jpg",
        alt: "Young woman takes photographs on an afternoon, while on the trail of one of Tasmania's many temperate rainforest.",
    },
];

export const employeeAvatars = [
    //
    "images/careers/avatars/12.jpg",
    "images/careers/avatars/03.jpg",
    "images/careers/avatars/19.jpg",
    "images/careers/avatars/20.jpg",
    "images/careers/avatars/04.jpg",
    "images/careers/avatars/31.jpg",
    "images/careers/avatars/14.jpg",
    "images/careers/avatars/16.jpg",
    "images/careers/avatars/11.jpg",
    //
    "images/careers/avatars/15.jpg",
    "images/careers/avatars/22.jpg",
    "images/careers/avatars/29.jpg",
    "images/careers/avatars/18.jpg",
    "images/careers/avatars/07.jpg",
    "images/careers/avatars/17.jpg",
    "images/careers/avatars/08.jpg",
    "images/careers/avatars/30.jpg",
    "images/careers/avatars/24.jpg",
    //
    "images/careers/avatars/21.jpg",
    "images/careers/avatars/06.jpg",
    "images/careers/avatars/09.jpg",
    "images/careers/avatars/23.jpg",
    "images/careers/avatars/28.jpg",
    "images/careers/avatars/26.jpg",
    "images/careers/avatars/27.jpg",
    "images/careers/avatars/33.jpg",
    "images/careers/avatars/32.jpg",
];
