import { careersPositionCount, careersPosition, careersPositionDraft, careersPositionsList, careersPositionsHomeList } from "../data/careers";

export const state = () => ({
    positions: [],
    homePositions: null,
    position: null,
    positionCount: 0,
});

export const mutations = {
    setPositions(state, positions) {
        state.positions = positions;
    },
    setHomePositions(state, homePositions) {
        state.homePositions = homePositions;
    },
    setPosition(state, position) {
        state.position = position;
    },
    setPositionCount(state, count) {
        state.positionCount = count;
    },
};

export const actions = {
    async fetchPositions(context) {
        try {
            const { positions } = await this.$sanity.fetch(careersPositionsList.getQuery());
            context.commit("setPositions", positions);
            return positions;
        } catch (error) {
            console.log("Failed to fetch positions: " + error);
        }
    },
    async fetchHomePositions(context) {
        if (!context.getters.getHomePositions) {
            try {
                const { positions } = await this.$sanity.fetch(careersPositionsHomeList.getQuery());
                context.commit("setHomePositions", positions);
            } catch (error) {
                console.log("Failed to fetch home positions: " + error);
            }
        }
    },
    async fetchPosition(context, payload) {
        try {
            let position;

            if (process.server && payload.params.id) {
                position = await this.$sanity.fetch(careersPositionDraft.getQuery(), careersPositionDraft.defineParameters({ id: payload.params.id }));
            } else {
                position = await this.$sanity.fetch(
                    careersPosition.getQuery(),
                    careersPosition.defineParameters({
                        slug: payload.slug,
                    })
                );
            }
            context.commit("setPosition", position);
            return position;
        } catch (error) {
            console.log("Failed to fetch position: " + error);
            throw error;
        }
    },
    async fetchPositionCount(context) {
        if (!context.getters.getPositionCount) {
            try {
                const { count } = await this.$sanity.fetch(careersPositionCount.getQuery());
                context.commit("setPositionCount", count);
            } catch (error) {
                console.log("Failed to fetch home positions: " + error);
            }
        }
    },
};

export const getters = {
    getPositions(state) {
        return state.positions;
    },
    getHomePositions(state) {
        return state.homePositions;
    },
    getPosition(state) {
        return state.position;
    },
    getPositionCount(state) {
        return state.positionCount;
    },
};
