import Vue from 'vue'
import defu from 'defu'

import { createClient } from '@nuxtjs/sanity'

import { SanityImage } from '@nuxtjs/sanity/dist/components/sanity-image'
Vue.component('SanityImage', SanityImage)

import { SanityContent } from '@nuxtjs/sanity/dist/components/sanity-content'
Vue.component('SanityContent', SanityContent)

const _options = JSON.parse('{"useCdn":false,"projectId":"skm640rd","dataset":"production","apiVersion":"2023-05-03","withCredentials":false,"token":"skcPm5XBW6UaCcUqxYfpHnYnYvLEk09GEWqkyxGzBBZgfScjp4oi8zdpWkCYWSk3do0PDjrAhArfN9GiQdmTt0ljuVcVisqsV7Vn92n33x7SNkpPBIuec9YGsTjThZpYzIUkSZ54QLKOnAnv216KgLIpAKZRQO4VVIeIf5f3iVwPQjEcScer"}')
const _additionalClients = JSON.parse('{}')

const createSanity = options => ({
  client: createClient(options),
  config: options,
  fetch(...args) {
    return this.client.fetch(...args)
  },
  setToken(token) {
    this.client = createClient({ ...options, token })
    this.options = ({ ...options, token })
  },
})

/**
 * @type {import('@nuxt/types').Plugin}
 */
export default async (context, inject) => {
  const { $config } = context
  const options = defu($config && $config.sanity || {}, _options)

  if (!options.disableSmartCdn && '$preview' in context) options.useCdn = false
  const additionalClients = defu($config && $config.sanity && $config.sanity.additionalClients || {}, _additionalClients)

  inject('sanity', {
    ...createSanity(options),
    ...Object.entries(additionalClients).reduce((clients, [name, clientOptions]) => {
      clients[name] = createSanity(defu(clientOptions, options))
      return clients
    }, {})
  })
}
