import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cb304cf4 = () => interopDefault(import('../pages/about-ideas-suggestions/index.vue' /* webpackChunkName: "pages/about-ideas-suggestions/index" */))
const _28039bb3 = () => interopDefault(import('../pages/ai.vue' /* webpackChunkName: "pages/ai" */))
const _8adab79e = () => interopDefault(import('../pages/beginners-series/index.vue' /* webpackChunkName: "pages/beginners-series/index" */))
const _10e107f4 = () => interopDefault(import('../pages/brand-use.vue' /* webpackChunkName: "pages/brand-use" */))
const _33a0fabd = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _310e6897 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2b82c614 = () => interopDefault(import('../pages/code-of-conduct/index.vue' /* webpackChunkName: "pages/code-of-conduct/index" */))
const _056cf904 = () => interopDefault(import('../pages/cookies-policy/index.vue' /* webpackChunkName: "pages/cookies-policy/index" */))
const _27f7791e = () => interopDefault(import('../pages/dreams/index.vue' /* webpackChunkName: "pages/dreams/index" */))
const _db5d6440 = () => interopDefault(import('../pages/insight/index.vue' /* webpackChunkName: "pages/insight/index" */))
const _dc145338 = () => interopDefault(import('../pages/pocket/index.vue' /* webpackChunkName: "pages/pocket/index" */))
const _2718f7ea = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _632ad8b6 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _558467b1 = () => interopDefault(import('../pages/procreate/index.vue' /* webpackChunkName: "pages/procreate/index" */))
const _7aca6b58 = () => interopDefault(import('../pages/reveal.vue' /* webpackChunkName: "pages/reveal" */))
const _77100d52 = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _28ef8c68 = () => interopDefault(import('../pages/terms-conditions/index.vue' /* webpackChunkName: "pages/terms-conditions/index" */))
const _7a7efcdc = () => interopDefault(import('../pages/who-we-are/index.vue' /* webpackChunkName: "pages/who-we-are/index" */))
const _396277d0 = () => interopDefault(import('../pages/app/privacy-policy.vue' /* webpackChunkName: "pages/app/privacy-policy" */))
const _64283780 = () => interopDefault(import('../pages/legal/trademark-guidelines.vue' /* webpackChunkName: "pages/legal/trademark-guidelines" */))
const _b86ce5c6 = () => interopDefault(import('../pages/legal/trademark-use-application.vue' /* webpackChunkName: "pages/legal/trademark-use-application" */))
const _de87be7c = () => interopDefault(import('../pages/procreate/3d.vue' /* webpackChunkName: "pages/procreate/3d" */))
const _69822b2d = () => interopDefault(import('../pages/procreate/accessibility.vue' /* webpackChunkName: "pages/procreate/accessibility" */))
const _5af68b87 = () => interopDefault(import('../pages/procreate/brushes.vue' /* webpackChunkName: "pages/procreate/brushes" */))
const _088e847c = () => interopDefault(import('../pages/procreate/color.vue' /* webpackChunkName: "pages/procreate/color" */))
const _3dbb1968 = () => interopDefault(import('../pages/procreate/superpowers.vue' /* webpackChunkName: "pages/procreate/superpowers" */))
const _55cd524c = () => interopDefault(import('../pages/procreate/video.vue' /* webpackChunkName: "pages/procreate/video" */))
const _08b4c861 = () => interopDefault(import('../pages/procreate/whats-new.vue' /* webpackChunkName: "pages/procreate/whats-new" */))
const _44c067fb = () => interopDefault(import('../pages/support/beta.vue' /* webpackChunkName: "pages/support/beta" */))
const _a161201a = () => interopDefault(import('../pages/careers/_position.vue' /* webpackChunkName: "pages/careers/_position" */))
const _1d79e236 = () => interopDefault(import('../pages/insight/_year/_slug.vue' /* webpackChunkName: "pages/insight/_year/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-ideas-suggestions",
    component: _cb304cf4,
    name: "about-ideas-suggestions___en___default"
  }, {
    path: "/ai",
    component: _28039bb3,
    name: "ai___en___default"
  }, {
    path: "/beginners-series",
    component: _8adab79e,
    name: "beginners-series___en___default"
  }, {
    path: "/brand-use",
    component: _10e107f4,
    name: "brand-use___en___default"
  }, {
    path: "/careers",
    component: _33a0fabd,
    name: "careers___en___default"
  }, {
    path: "/cn",
    component: _310e6897,
    name: "index___cn"
  }, {
    path: "/code-of-conduct",
    component: _2b82c614,
    name: "code-of-conduct___en___default"
  }, {
    path: "/cookies-policy",
    component: _056cf904,
    name: "cookies-policy___en___default"
  }, {
    path: "/dreams",
    component: _27f7791e,
    name: "dreams___en___default"
  }, {
    path: "/en",
    component: _310e6897,
    name: "index___en"
  }, {
    path: "/insight",
    component: _db5d6440,
    name: "insight___en___default"
  }, {
    path: "/jp",
    component: _310e6897,
    name: "index___jp"
  }, {
    path: "/pocket",
    component: _dc145338,
    name: "pocket___en___default"
  }, {
    path: "/press",
    component: _2718f7ea,
    name: "press___en___default"
  }, {
    path: "/privacy-policy",
    component: _632ad8b6,
    name: "privacy-policy___en___default"
  }, {
    path: "/procreate",
    component: _558467b1,
    name: "procreate___en___default"
  }, {
    path: "/reveal",
    component: _7aca6b58,
    name: "reveal___en___default"
  }, {
    path: "/support",
    component: _77100d52,
    name: "support___en___default"
  }, {
    path: "/terms-conditions",
    component: _28ef8c68,
    name: "terms-conditions___en___default"
  }, {
    path: "/who-we-are",
    component: _7a7efcdc,
    name: "who-we-are___en___default"
  }, {
    path: "/app/privacy-policy",
    component: _396277d0,
    name: "app-privacy-policy___en___default"
  }, {
    path: "/cn/about-ideas-suggestions",
    component: _cb304cf4,
    name: "about-ideas-suggestions___cn"
  }, {
    path: "/cn/ai",
    component: _28039bb3,
    name: "ai___cn"
  }, {
    path: "/cn/beginners-series",
    component: _8adab79e,
    name: "beginners-series___cn"
  }, {
    path: "/cn/brand-use",
    component: _10e107f4,
    name: "brand-use___cn"
  }, {
    path: "/cn/careers",
    component: _33a0fabd,
    name: "careers___cn"
  }, {
    path: "/cn/code-of-conduct",
    component: _2b82c614,
    name: "code-of-conduct___cn"
  }, {
    path: "/cn/cookies-policy",
    component: _056cf904,
    name: "cookies-policy___cn"
  }, {
    path: "/cn/dreams",
    component: _27f7791e,
    name: "dreams___cn"
  }, {
    path: "/cn/insight",
    component: _db5d6440,
    name: "insight___cn"
  }, {
    path: "/cn/pocket",
    component: _dc145338,
    name: "pocket___cn"
  }, {
    path: "/cn/press",
    component: _2718f7ea,
    name: "press___cn"
  }, {
    path: "/cn/privacy-policy",
    component: _632ad8b6,
    name: "privacy-policy___cn"
  }, {
    path: "/cn/procreate",
    component: _558467b1,
    name: "procreate___cn"
  }, {
    path: "/cn/reveal",
    component: _7aca6b58,
    name: "reveal___cn"
  }, {
    path: "/cn/support",
    component: _77100d52,
    name: "support___cn"
  }, {
    path: "/cn/terms-conditions",
    component: _28ef8c68,
    name: "terms-conditions___cn"
  }, {
    path: "/cn/who-we-are",
    component: _7a7efcdc,
    name: "who-we-are___cn"
  }, {
    path: "/en/about-ideas-suggestions",
    component: _cb304cf4,
    name: "about-ideas-suggestions___en"
  }, {
    path: "/en/ai",
    component: _28039bb3,
    name: "ai___en"
  }, {
    path: "/en/beginners-series",
    component: _8adab79e,
    name: "beginners-series___en"
  }, {
    path: "/en/brand-use",
    component: _10e107f4,
    name: "brand-use___en"
  }, {
    path: "/en/careers",
    component: _33a0fabd,
    name: "careers___en"
  }, {
    path: "/en/code-of-conduct",
    component: _2b82c614,
    name: "code-of-conduct___en"
  }, {
    path: "/en/cookies-policy",
    component: _056cf904,
    name: "cookies-policy___en"
  }, {
    path: "/en/dreams",
    component: _27f7791e,
    name: "dreams___en"
  }, {
    path: "/en/insight",
    component: _db5d6440,
    name: "insight___en"
  }, {
    path: "/en/pocket",
    component: _dc145338,
    name: "pocket___en"
  }, {
    path: "/en/press",
    component: _2718f7ea,
    name: "press___en"
  }, {
    path: "/en/privacy-policy",
    component: _632ad8b6,
    name: "privacy-policy___en"
  }, {
    path: "/en/procreate",
    component: _558467b1,
    name: "procreate___en"
  }, {
    path: "/en/reveal",
    component: _7aca6b58,
    name: "reveal___en"
  }, {
    path: "/en/support",
    component: _77100d52,
    name: "support___en"
  }, {
    path: "/en/terms-conditions",
    component: _28ef8c68,
    name: "terms-conditions___en"
  }, {
    path: "/en/who-we-are",
    component: _7a7efcdc,
    name: "who-we-are___en"
  }, {
    path: "/jp/about-ideas-suggestions",
    component: _cb304cf4,
    name: "about-ideas-suggestions___jp"
  }, {
    path: "/jp/ai",
    component: _28039bb3,
    name: "ai___jp"
  }, {
    path: "/jp/beginners-series",
    component: _8adab79e,
    name: "beginners-series___jp"
  }, {
    path: "/jp/brand-use",
    component: _10e107f4,
    name: "brand-use___jp"
  }, {
    path: "/jp/careers",
    component: _33a0fabd,
    name: "careers___jp"
  }, {
    path: "/jp/code-of-conduct",
    component: _2b82c614,
    name: "code-of-conduct___jp"
  }, {
    path: "/jp/cookies-policy",
    component: _056cf904,
    name: "cookies-policy___jp"
  }, {
    path: "/jp/dreams",
    component: _27f7791e,
    name: "dreams___jp"
  }, {
    path: "/jp/insight",
    component: _db5d6440,
    name: "insight___jp"
  }, {
    path: "/jp/pocket",
    component: _dc145338,
    name: "pocket___jp"
  }, {
    path: "/jp/press",
    component: _2718f7ea,
    name: "press___jp"
  }, {
    path: "/jp/privacy-policy",
    component: _632ad8b6,
    name: "privacy-policy___jp"
  }, {
    path: "/jp/procreate",
    component: _558467b1,
    name: "procreate___jp"
  }, {
    path: "/jp/reveal",
    component: _7aca6b58,
    name: "reveal___jp"
  }, {
    path: "/jp/support",
    component: _77100d52,
    name: "support___jp"
  }, {
    path: "/jp/terms-conditions",
    component: _28ef8c68,
    name: "terms-conditions___jp"
  }, {
    path: "/jp/who-we-are",
    component: _7a7efcdc,
    name: "who-we-are___jp"
  }, {
    path: "/legal/trademark-guidelines",
    component: _64283780,
    name: "legal-trademark-guidelines___en___default"
  }, {
    path: "/legal/trademark-use-application",
    component: _b86ce5c6,
    name: "legal-trademark-use-application___en___default"
  }, {
    path: "/procreate/3d",
    component: _de87be7c,
    name: "procreate-3d___en___default"
  }, {
    path: "/procreate/accessibility",
    component: _69822b2d,
    name: "procreate-accessibility___en___default"
  }, {
    path: "/procreate/brushes",
    component: _5af68b87,
    name: "procreate-brushes___en___default"
  }, {
    path: "/procreate/color",
    component: _088e847c,
    name: "procreate-color___en___default"
  }, {
    path: "/procreate/superpowers",
    component: _3dbb1968,
    name: "procreate-superpowers___en___default"
  }, {
    path: "/procreate/video",
    component: _55cd524c,
    name: "procreate-video___en___default"
  }, {
    path: "/procreate/whats-new",
    component: _08b4c861,
    name: "procreate-whats-new___en___default"
  }, {
    path: "/support/beta",
    component: _44c067fb,
    name: "support-beta___en___default"
  }, {
    path: "/cn/app/privacy-policy",
    component: _396277d0,
    name: "app-privacy-policy___cn"
  }, {
    path: "/cn/legal/trademark-guidelines",
    component: _64283780,
    name: "legal-trademark-guidelines___cn"
  }, {
    path: "/cn/legal/trademark-use-application",
    component: _b86ce5c6,
    name: "legal-trademark-use-application___cn"
  }, {
    path: "/cn/procreate/3d",
    component: _de87be7c,
    name: "procreate-3d___cn"
  }, {
    path: "/cn/procreate/accessibility",
    component: _69822b2d,
    name: "procreate-accessibility___cn"
  }, {
    path: "/cn/procreate/brushes",
    component: _5af68b87,
    name: "procreate-brushes___cn"
  }, {
    path: "/cn/procreate/color",
    component: _088e847c,
    name: "procreate-color___cn"
  }, {
    path: "/cn/procreate/superpowers",
    component: _3dbb1968,
    name: "procreate-superpowers___cn"
  }, {
    path: "/cn/procreate/video",
    component: _55cd524c,
    name: "procreate-video___cn"
  }, {
    path: "/cn/procreate/whats-new",
    component: _08b4c861,
    name: "procreate-whats-new___cn"
  }, {
    path: "/cn/support/beta",
    component: _44c067fb,
    name: "support-beta___cn"
  }, {
    path: "/en/app/privacy-policy",
    component: _396277d0,
    name: "app-privacy-policy___en"
  }, {
    path: "/en/legal/trademark-guidelines",
    component: _64283780,
    name: "legal-trademark-guidelines___en"
  }, {
    path: "/en/legal/trademark-use-application",
    component: _b86ce5c6,
    name: "legal-trademark-use-application___en"
  }, {
    path: "/en/procreate/3d",
    component: _de87be7c,
    name: "procreate-3d___en"
  }, {
    path: "/en/procreate/accessibility",
    component: _69822b2d,
    name: "procreate-accessibility___en"
  }, {
    path: "/en/procreate/brushes",
    component: _5af68b87,
    name: "procreate-brushes___en"
  }, {
    path: "/en/procreate/color",
    component: _088e847c,
    name: "procreate-color___en"
  }, {
    path: "/en/procreate/superpowers",
    component: _3dbb1968,
    name: "procreate-superpowers___en"
  }, {
    path: "/en/procreate/video",
    component: _55cd524c,
    name: "procreate-video___en"
  }, {
    path: "/en/procreate/whats-new",
    component: _08b4c861,
    name: "procreate-whats-new___en"
  }, {
    path: "/en/support/beta",
    component: _44c067fb,
    name: "support-beta___en"
  }, {
    path: "/jp/app/privacy-policy",
    component: _396277d0,
    name: "app-privacy-policy___jp"
  }, {
    path: "/jp/legal/trademark-guidelines",
    component: _64283780,
    name: "legal-trademark-guidelines___jp"
  }, {
    path: "/jp/legal/trademark-use-application",
    component: _b86ce5c6,
    name: "legal-trademark-use-application___jp"
  }, {
    path: "/jp/procreate/3d",
    component: _de87be7c,
    name: "procreate-3d___jp"
  }, {
    path: "/jp/procreate/accessibility",
    component: _69822b2d,
    name: "procreate-accessibility___jp"
  }, {
    path: "/jp/procreate/brushes",
    component: _5af68b87,
    name: "procreate-brushes___jp"
  }, {
    path: "/jp/procreate/color",
    component: _088e847c,
    name: "procreate-color___jp"
  }, {
    path: "/jp/procreate/superpowers",
    component: _3dbb1968,
    name: "procreate-superpowers___jp"
  }, {
    path: "/jp/procreate/video",
    component: _55cd524c,
    name: "procreate-video___jp"
  }, {
    path: "/jp/procreate/whats-new",
    component: _08b4c861,
    name: "procreate-whats-new___jp"
  }, {
    path: "/jp/support/beta",
    component: _44c067fb,
    name: "support-beta___jp"
  }, {
    path: "/",
    component: _310e6897,
    name: "index___en___default"
  }, {
    path: "/cn/careers/:position",
    component: _a161201a,
    name: "careers-position___cn"
  }, {
    path: "/en/careers/:position",
    component: _a161201a,
    name: "careers-position___en"
  }, {
    path: "/jp/careers/:position",
    component: _a161201a,
    name: "careers-position___jp"
  }, {
    path: "/cn/insight/:year/:slug?",
    component: _1d79e236,
    name: "insight-year-slug___cn"
  }, {
    path: "/en/insight/:year/:slug?",
    component: _1d79e236,
    name: "insight-year-slug___en"
  }, {
    path: "/jp/insight/:year/:slug?",
    component: _1d79e236,
    name: "insight-year-slug___jp"
  }, {
    path: "/careers/:position",
    component: _a161201a,
    name: "careers-position___en___default"
  }, {
    path: "/insight/:year/:slug?",
    component: _1d79e236,
    name: "insight-year-slug___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
